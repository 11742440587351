import cache from './cacheExchange';
import { dedupExchange } from './dedupExchange';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { Translate } from 'next-translate';
import { ClientOptions, CombinedError, errorExchange, fetchExchange, SSRExchange } from 'urql';
import { getAuthExchangeOptions } from 'urql/authExchange';
import { removeTokensFromCookies } from 'utils/Auth/TokensFromCookies';

export const getUrqlExchanges = (
    ssrExchange: SSRExchange,
    t: Translate,
    context?: GetServerSidePropsContext | NextPageContext,
): ClientOptions['exchanges'] => [
    devtoolsExchange,
    dedupExchange,
    cache,
    errorExchange({
        onError: (error: CombinedError) => {
            const isAuthError = error.response?.status === 401;

            if (isAuthError) {
                removeTokensFromCookies(context);
            }
        },
    }),
    ssrExchange,
    authExchange(getAuthExchangeOptions(context)),
    fetchExchange,
];
