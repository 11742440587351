import { GetServerSidePropsContext, NextPageContext } from 'next';
import { setCookie } from 'nookies';
import { getIsHttps, getProtocol } from 'utils/requestProtocol';

export const setTokensToCookies = (
    accessToken: string,
    refreshToken: string,
    context?: GetServerSidePropsContext | NextPageContext,
): void => {
    setCookie(undefined, 'accessToken', accessToken, {
        req: context?.req,
        res: context?.res,
        path: '/',
        secure: getIsHttps(getProtocol(context)),
    });
    setCookie(undefined, 'refreshToken', refreshToken, {
        req: context?.req,
        res: context?.res,
        maxAge: 3600 * 24 * 14,
        path: '/',
        secure: getIsHttps(getProtocol(context)),
    });
};
